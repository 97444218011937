import React from 'react'
import { PaginaType } from 'utils/types'
import { useTrans } from 'hooks'
import { Page } from 'components/organisms'
import { WContainer } from 'components/atoms'
import { BrandCard, HomepageBoxCard, MyAccountSection } from '@components/molecules'
import styles from './HomeTemplate.module.sass'

interface Props {
  pagina: PaginaType
}

const HomeTemplate = (props: Props) => {
  const { pagina } = props

  const t = useTrans()

  return (
    <Page pagina={pagina}>
      <div>
        <div className={styles.main}>
          <h1 className={styles.title}>{t(pagina.titolo)}</h1>
        </div>
        <WContainer variant="sm">
          <div className={styles.quarter}>
            <HomepageBoxCard type="scrivici" />
            <HomepageBoxCard type="chiamaci" />
            <HomepageBoxCard type="iscrivitiAllaNewsletter" />
            <HomepageBoxCard type="concorsiEPromozioni" />
            <HomepageBoxCard type="gestisciPreferenze" />
          </div>
          <MyAccountSection>
            <BrandCard />
          </MyAccountSection>
        </WContainer>
      </div>
    </Page>
  )
}

export default HomeTemplate
